html,
body {
    margin: 0;
    min-height: 100vh;
    width: 100vw;
    font-family: 'VerizonNHGeDS';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    min-height: 100vh;
    width: 100vw;
    display: flex;
}
@font-face {
    font-family: 'VerizonNHGeDS';
    src: url(./core-ui/Theme/VerizonNHGDS-Regular.ttf) format('truetype');
    font-style: normal;
    font-weight: 400;
}
